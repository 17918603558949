import { api } from "./api";

const URLS = {
    opportunity: "networkings",
    myOpportunities: "networkings/current-user",
};

export const getAllOpportunities = () => {
    return api.get(`${URLS.opportunity}`);
};

export const getUserOpportunities = () => {
    return api.get(`${URLS.myOpportunities}`);
};

export const showOpportunity = (payload) => {
    return api.get(`${URLS.opportunity}/${payload.id}`);
};

export const getParams = (payload) => {
    return api.get(`${URLS.opportunity}/params`);
};

export const createOpportunity = (payload) => {
    return api.post(`${URLS.opportunity}`, payload);
};

export const updateOpportunity = (payload) => {
    return api.patch(`${URLS.opportunity}/${payload.id}`, payload.data);
};

export const deleteOpportunity = (payload) => {
    return api.delete(`${URLS.opportunity}/${payload.id}`);
};
