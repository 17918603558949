<template>
    <v-container>
        <v-row class="my-10">
            <v-col
                v-if="getOpportunityStatus_Pending || getOpportunityStatus_Idle"
                style="min-height: 600px"
            >
                <loading-spinner></loading-spinner>
            </v-col>
            <v-col v-else-if="getOpportunityStatus_Error" cols="12">
                <span class="text-h6">
                    {{ $t("ErrorMessages.somethingWrong") }}
                </span>
            </v-col>
            <template v-else>
                <v-col cols="12" class="d-md-flex align-center">
                    <div class="d-flex align-center">
                        <v-btn
                            icon
                            color="accent darken-3"
                            class="align-self-center"
                            link
                            @click="$router.go(-1)"
                        >
                            <v-icon left> fa-solid fa-chevron-left </v-icon>
                        </v-btn>
                        <h2 class="primary--text main-heading">
                            {{ item.title }}
                        </h2>
                        <v-divider class="mx-4" inset vertical> </v-divider>
                        <span class="main-heading" style="font-size: 1.125rem">
                            by {{ item.user.organization_name }}
                        </span>
                    </div>

                    <v-spacer></v-spacer>

                    <v-btn
                        v-if="item.user.id == userId"
                        class="pa-5 pr-8"
                        color="primary"
                        text
                        outlined
                        depressed
                        :to="{
                            name: 'NetworkingOpportunitiesUpdate',
                            params: { id: item.id },
                        }"
                    >
                        <v-icon x-small left> fa-solid fa-pencil </v-icon>
                        {{ $t("SME.SolutionCard.editButton") }}
                    </v-btn>
                </v-col>

                <v-col cols="12">
                    <base-card class="card">
                        <template #content>
                            <v-row justify="center">
                                <v-col
                                    cols="12"
                                    class="text-h5 text-center primary--text"
                                >
                                    {{ item.partnership }}
                                </v-col>

                                <v-col cols="12" md="4" class="text-center">
                                    <h2 class="main-heading">Where</h2>
                                    <v-icon
                                        class="mr-5"
                                        color="primary"
                                        size="48"
                                    >
                                        fas fa-earth-europe
                                    </v-icon>
                                    <template
                                        v-for="country in item.partnership_countries"
                                    >
                                        <div
                                            :key="country"
                                            class="mr-5 d-inline-block main-heading"
                                            style="font-size: 1.45rem"
                                        >
                                            {{ country }}
                                        </div>
                                    </template>
                                </v-col>
                                <v-col cols="12" md="4" class="text-center">
                                    <h2 class="main-heading">When...</h2>
                                    <span
                                        >{{ parseDate(item.date_from) }} -
                                    </span>
                                    <span>{{ parseDate(item.date_to) }}</span>
                                </v-col>

                                <v-col cols="12" md="4" class="text-center">
                                    <h2 class="main-heading">
                                        Partnership types
                                    </h2>

                                    <span
                                        v-for="type in item.partnership_types"
                                        :key="type"
                                    >
                                        <v-icon
                                            size="18"
                                            class="mr-1"
                                            color="purple"
                                        >
                                            fas fa-hashtag
                                        </v-icon>
                                        {{ type }}
                                    </span>
                                </v-col>

                                <v-col
                                    cols="12"
                                    class="text-center d-flex flex-column align-center"
                                >
                                    <h2 class="main-heading">About</h2>
                                    <div class="divider_container" />
                                    <p class="mb-0">{{ item.description }}</p>
                                </v-col>
                            </v-row>
                        </template>
                    </base-card>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import { apiStatus } from "../api/constants/apiStatus";
import { apiStatusComputed } from "../api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import { showOpportunity } from "@/api/networkingOpportunitiesApi.js";

import LoadingSpinner from "../components/LoadingSpinner.vue";

export default {
    name: "NetworkingOpportunitiesDetails",

    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },

    components: { LoadingSpinner },

    data() {
        return {
            item: {},
            getOpportunityStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["getOpportunityStatus"]),
        ...mapGetters({
            userId: "auth/getUserId",
        }),
    },

    methods: {
        async handleOpportunity() {
            this.getOpportunityStatus = apiStatus.Pending;

            const { response, error } = await withAsync(showOpportunity, {
                id: this.id,
            });

            if (error) {
                this.getOpportunityStatus = apiStatus.Error;
                return;
            }
            const data = response.data.data;

            const reduced = Object.entries(data).reduce((acc, [key, value]) => {
                let computedValue;
                if (key === "user") computedValue = value;
                else if (Array.isArray(value)) {
                    computedValue = value.map((item) => item.name);
                } else if (typeof value === "object")
                    computedValue = value.name;
                else if (key.includes("date")) {
                    const [date, time] = value.split(" ");
                    computedValue = date;
                } else computedValue = value;
                acc[key] = computedValue;
                return acc;
            }, {});
            this.item = reduced;
            this.getOpportunityStatus = apiStatus.Success;
        },

        parseDate(date) {
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
    },

    created() {
        this.handleOpportunity();
    },
};
</script>

<style scoped>
.card {
    background: #eee !important;
    /* background: linear-gradient(
        -35deg,
        var(--v-primary-base),
        var(--v-primary-lighten1) 15%,
        var(--v-primary-lighten2) 25%,
        white 26%,
        white 84%,
        var(--v-primary-lighten2) 85%,
        var(--v-primary-lighten1) 90%,
        var(--v-primary-base)
    ); */
}
.divider_container {
    width: 30px;
    border-bottom: 3px solid #c35272;
    margin-top: -5px;
    margin-bottom: 1.5rem;
}
</style>
